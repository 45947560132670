<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">{{ $t(title) }}</h4>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="float-end d-none d-md-block">
          <router-link to="/flight-bookings">
            <button class="btn btn-primary">
              {{ $t('back') }}
              <i class="mdi mdi-keyboard-backspace"></i>
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 page-loader-div">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-12 page-loader-parent">
                <div class="page-loader">
                  <md-progress-spinner :md-diameter="50" :md-stroke="5" md-mode="indeterminate"></md-progress-spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row content-div d-none2">
      <div class="col-md-3  text-center">
        <div class="card">
          <div class="card-body p-0 pt-4">
            <div class="row">
              <div class="col-md-12">
                <div class="inline-block vertical-middle">
                  <h3 class="bold from-txt mb0 prime-color">{{ booking.from }}</h3>
                  <span class="clearfix from-full-txt">{{ departure }}</span>
                  <span class="from-time"></span>
                </div>
                <div class="inline-block vertical-middle">
                  <div v-if="booking.return_date" class="flip roundtrip"></div>
                  <div v-else class="flip oneway"></div>
                </div>
                <div class="inline-block vertical-middle">
                  <h3 class="bold to-txt mb0 prime-color">{{ booking.to }}</h3>
                  <span class="clearfix to-full-txt to-full-txt">{{ arrival }}</span>
                  <span class="to-time"></span>
                </div>
              </div>
              <div class="col-md-12 mt-3 menu-info">
                <a class="tab-link active" href="#" data-id="#booking-info">
                  تفاصيل الحجز
                </a>
                <a class="tab-link" href="#" data-id="#trip-info">
                  تفاصيل الرحلة
                </a>
                <a class="tab-link" href="#" data-id="#company-info">
                  معلومات الشركة
                </a>
                <a class="tab-link" href="#" data-id="#passengers-info">
                  الركاب
                </a>
                <a class="tab-link" href="#" data-id="#user-info">
                  المستخدم
                </a>
                <a class="tab-link" href="#" data-id="#transaction-info">
                  معاملة الحجز
                </a>
                <a class="tab-link" href="#" data-id="#activities-info">
                  نشاط الحجز
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <router-link :to="'/chat-support/customers/' + booking?.user?.id + '?type=user'">
              <b-button variant="primary" class="pl-3 pr-3 w-100">
                التواصل مع المستخدم
              </b-button>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <div class="tab-content" id="booking-info">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <h6 class="text-primary">
                    معلومات الحجز
                  </h6>
                </div>
                <div class="row booking-details">
                  <div class="col-md-4">
                    <p>الموعد النهائي للتذكرة</p>
                  </div>
                  <div class="col-md-8">
                    <p>{{ flight_info?.lastTicketingDate }}</p>
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>
                  <div class="col-md-4">
                    <p>تاريخ الاصدار</p>
                  </div>
                  <div class="col-md-8">
                    N/A
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>
                  <div class="col-md-4">
                    <p>تاريخ الطلب</p>
                  </div>
                  <div class="col-md-8">
                    {{ booking.booking_date }}
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>
                  <div class="col-md-4">
                    <p>تاريخ الذهاب</p>
                  </div>
                  <div class="col-md-8">
                    {{ booking.departure_date }}
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>
                  <div class="col-md-4">
                    <p>تاريخ العودة</p>
                  </div>
                  <div class="col-md-8">
                    {{ booking.return_date ?? 'N/A' }}
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>
                  <div class="col-md-4">
                    <p>بوابة الدفع</p>
                  </div>
                  <div class="col-md-8">
                    N/A
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>
                  <div class="col-md-4">
                    <p>انشأ من قبل</p>
                  </div>
                  <div class="col-md-8">
                    {{ booking.user?.first_name }} {{ booking.user?.last_name }}
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>
                  <div class="col-md-4">
                    <p>الركاب</p>
                  </div>
                  <div class="col-md-8">
                    <span v-if="booking.adult > 0">{{ booking.adult }} {{ $t('Adult') }}</span>
                    <span v-if="booking.children > 0">, {{ booking.children }} {{ $t('Child')
                    }}</span>
                    <span v-if="booking.infant > 0">, {{ booking.infant }}
                      {{ $t('Infant') }}</span>
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>
                  <div class="col-md-4">
                    <p>الحالة</p>
                  </div>
                  <div class="col-md-8">
                    <span v-bind:class="booking.status"> {{
                      $t(booking.status) }}</span>
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>
                  <div class="col-md-4">
                    <p>رسالة الخطأ</p>
                  </div>
                  <div class="col-md-8">
                    N/A
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>
                  <div class="col-md-4">
                    <p>الرسوم الاساسية</p>
                  </div>
                  <div class="col-md-8">
                    {{ parseFloat(booking.amount).toFixed(2) }} {{ $t(booking.currency) }}
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>
                  <div class="col-md-4">
                    <p>الضريبة</p>
                  </div>
                  <div class="col-md-8">
                    {{ parseFloat(booking.vat_amount).toFixed(2) }} {{ $t(booking.currency) }}
                  </div>
                  <div class="col-md-12">
                    <hr>
                  </div>
                  <div class="col-md-4">
                    <p>الرسوم</p>
                  </div>
                  <div class="col-md-8">
                    {{ parseFloat(booking.total).toFixed(2) }} {{ $t(booking.currency) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <h6 class="text-primary">
                    معلومات التواصل
                  </h6>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="row booking-details">
                    <div class="col-md-4">
                      <p>البريد الالكتروني</p>
                    </div>
                    <div class="col-md-8">
                      <p>{{ flight_info?.user?.email ?? 'N/A' }}</p>
                    </div>
                    <div class="col-md-12">
                      <hr>
                    </div>
                    <div class="col-md-4">
                      <p>رقم الجوال</p>
                    </div>
                    <div class="col-md-8">
                      <p>{{ flight_info?.user?.phone_country_code ?? 'N/A' }}{{ flight_info?.user?.phone }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class=" tab-content d-none2" id="trip-info">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <h6 class="text-primary">
                    رحلة الذهاب
                  </h6>
                </div>
                <div class="col-md-12">
                  <div v-for="(segment, index) in departure_flight?.segments" :key="index"
                    class="row align-items-center no-margin mb15 p20 fl-itinerary">
                    <div v-if="index > 0" class="col-md-12 col-12 mb-4 text-center">
                      <div class="clearfix layover m0">
                        <span>
                          {{ get_stop_duration(segment, departure_flight?.segments[index -
                            1]).hours }}س
                          {{ get_stop_duration(segment, departure_flight?.segments[index -
                            1]).minutes }}د {{ $t('Stop') }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-4 col-12 mt20 text-center">
                      <div>
                        <h3 class="bold prime-color">{{ segment.departure.iataCode }}</h3>
                        <h4 class="no-margin new-light text-center">{{ new
                          Date(segment.departure.at).toLocaleTimeString('fr-FR', {
                            hour:
                              '2-digit', minute: '2-digit'
                          }) }}</h4>
                        <span>{{ new
                          Date(segment.departure.at).toLocaleDateString('en-US',
                            { day: 'numeric' }) }} {{ $t(new
      Date(segment.departure.at).toLocaleDateString('en-US',
        {
          month:
            'long'
        })) }} {{ new
      Date(segment.departure.at).getFullYear() }}</span>
                      </div>
                      <div>
                        <div class="bold ellipsis no-wrap">
                          {{ get_airport_info(segment.departure.iataCode)[0]['name'] }}
                        </div>
                        <div class="ellipsis no-wrap"> {{
                          get_airport_info(segment.departure.iataCode)[0]['city'] }}, {{
    get_airport_info(segment.departure.iataCode)[0]['country'] }}</div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12 text-center">
                      <div class="mb5">
                        <div class="fl-img-con">
                          <div v-bind:class="'flight-image i-' + segment.carrierCode.toString().toLowerCase()">
                          </div>
                        </div>
                        <p class="no-margin font-12">
                          <span class="clearfix">{{ segment.carrierCode }}{{ segment.number
                          }}</span>
                        </p>
                        <span>{{ $t(booking.cabin) }}</span>
                        <div class="clearfix">
                          <div class="oneway"></div>
                        </div>
                      </div>
                      <div>
                        <div></div>
                        <div>{{ convertDuration(segment.duration) }}</div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12 mt20 text-center">
                      <div>
                        <h3 class="bold prime-color">{{ segment.arrival.iataCode }}</h3>
                        <h4 class="no-margin new-light text-center">
                          {{ new
                            Date(segment.arrival.at).toLocaleTimeString('fr-FR', {
                              hour:
                                '2-digit', minute: '2-digit'
                            }) }}
                        </h4>
                        <span>
                          {{ new
                            Date(segment.arrival.at).toLocaleDateString('en-US',
                              { day: 'numeric' }) }} {{ $t(new
      Date(segment.arrival.at).toLocaleDateString('en-US',
        {
          month:
            'long'
        })) }} {{ new
      Date(segment.arrival.at).getFullYear() }}
                        </span>
                      </div>
                      <div>
                        <div class="bold ellipsis no-wrap">
                          {{ get_airport_info(segment.arrival.iataCode)[0]['name'] }}
                        </div>
                        <div class="ellipsis no-wrap">
                          {{ get_airport_info(segment.arrival.iataCode)[0]['city'] }}, {{
                            get_airport_info(segment.departure.iataCode)[0]['country'] }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <h6 class="text-primary">
                    رحلة العودة
                  </h6>
                </div>
                <div class="col-md-12">
                  <div v-for="(segment, index) in arrival_flight?.segments" :key="index"
                    class="row align-items-center no-margin mb15 p20">
                    <div v-if="index > 0" class="col-md-12 col-12 mb-4 text-center">
                      <div class="clearfix layover m0">
                        <span>
                          {{ get_stop_duration(segment, arrival_flight?.segments[index -
                            1]).hours }}س
                          {{ get_stop_duration(segment, arrival_flight?.segments[index -
                            1]).minutes }}د {{ $t('Stop') }}
                        </span>
                      </div>
                    </div>
                    <div class="col-md-4 col-12 mt20 text-center">
                      <div>
                        <h3 class="bold prime-color">{{ segment.departure.iataCode }}</h3>
                        <h4 class="no-margin new-light text-center">{{ new
                          Date(segment.departure.at).toLocaleTimeString('fr-FR', {
                            hour:
                              '2-digit', minute: '2-digit'
                          }) }}</h4>
                        <span>{{ new
                          Date(segment.departure.at).toLocaleDateString('en-US',
                            { day: 'numeric' }) }} {{ $t(new
      Date(segment.departure.at).toLocaleDateString('en-US',
        {
          month:
            'long'
        })) }} {{ new
      Date(segment.departure.at).getFullYear() }}</span>
                      </div>
                      <div>
                        <div class="bold ellipsis no-wrap">
                          {{ get_airport_info(segment.departure.iataCode)[0]['name'] }}
                        </div>
                        <div class="ellipsis no-wrap"> {{
                          get_airport_info(segment.departure.iataCode)[0]['city'] }}, {{
    get_airport_info(segment.departure.iataCode)[0]['country'] }}</div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12 text-center">
                      <div class="mb5">
                        <div class="fl-img-con">
                          <div v-bind:class="'flight-image i-' + segment.carrierCode.toString().toLowerCase()">
                          </div>
                        </div>
                        <p class="no-margin font-12">
                          <span class="clearfix">{{ segment.carrierCode }}{{ segment.number
                          }}</span>
                        </p>
                        <span>{{ $t(booking.cabin) }}</span>
                        <div class="clearfix">
                          <div class="oneway"></div>
                        </div>
                      </div>
                      <div>
                        <div></div>
                        <div>{{ convertDuration(segment.duration) }}</div>
                      </div>
                    </div>
                    <div class="col-md-4 col-12 mt20 text-center">
                      <div>
                        <h3 class="bold prime-color">{{ segment.arrival.iataCode }}</h3>
                        <h4 class="no-margin new-light text-center">
                          {{ new
                            Date(segment.arrival.at).toLocaleTimeString('fr-FR', {
                              hour:
                                '2-digit', minute: '2-digit'
                            }) }}
                        </h4>
                        <span>
                          {{ new
                            Date(segment.arrival.at).toLocaleDateString('en-US',
                              { day: 'numeric' }) }} {{ $t(new
      Date(segment.arrival.at).toLocaleDateString('en-US',
        {
          month:
            'long'
        })) }} {{ new
      Date(segment.arrival.at).getFullYear() }}
                        </span>
                      </div>
                      <div>
                        <div class="bold ellipsis no-wrap">
                          {{ get_airport_info(segment.arrival.iataCode)[0]['name'] }}
                        </div>
                        <div class="ellipsis no-wrap">
                          {{ get_airport_info(segment.arrival.iataCode)[0]['city'] }}, {{
                            get_airport_info(segment.departure.iataCode)[0]['country'] }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card tab-content d-none2" id="company-info">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-12  mb-3">
                <h6 class="text-primary">
                  معلومات الشركة
                </h6>
              </div>
              <div class="col-md-4 col-6">
                <p class="m-0">معرف الشركة</p>
              </div>
              <div class="col-md-8 col-6">
                <p class="text-primary m-0">
                  <router-link :to="'/companies/show/' + encodeId(booking?.company?.id)">
                    {{ booking?.company?.id }}
                  </router-link>
                </p>
              </div>
              <div class="col-md-12">
                <hr>
              </div>
              <div class="col-md-4 col-6">
                <p class="m-0">اسم الشركة</p>
              </div>
              <div class="col-md-8 col-6">
                <p class="m-0">{{ booking?.company?.company_name }}</p>
              </div>
              <div class="col-md-12">
                <hr>
              </div>
              <div class="col-md-4 col-6">
                <p class="m-0">رقم جوال </p>
              </div>
              <div class="col-md-8 col-6">
                <p class="m-0">{{ booking?.company?.phone_country_code }}{{ booking?.company?.phone }}</p>
              </div>
              <div class="col-md-12">
                <hr>
              </div>
              <div class="col-md-4 col-6">
                <p class="m-0">البريد الالكتروني</p>
              </div>
              <div class="col-md-8 col-6">
                <p class="m-0">{{ booking?.company?.email }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card tab-content d-none2" id="passengers-info">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-2 mb-3">
                <h6 class="text-primary m-0">
                  الركاب
                </h6>
              </div>
              <div class="col-md-12">
                <md-table v-model="booking.passengers" md-sort="name" md-sort-order="asc" md-card>
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="اسم الراكب" md-numeric>
                      {{ item?.passenger?.first_name }}{{ item?.passenger?.last_name }}
                    </md-table-cell>
                    <md-table-cell md-label="نوع الراكب" md-numeric>
                      {{ $t(item?.passenger?.type) }}
                    </md-table-cell>
                    <md-table-cell md-label="وثيقة السفر" md-numeric>
                      {{ item?.passenger?.IDNumber }}-جواز سفر
                    </md-table-cell>
                    <md-table-cell md-label="البريد الالكتروني" md-numeric>
                      {{ item?.passenger?.email }}
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </div>
            </div>
          </div>
        </div>
        <div class="card tab-content d-none2" id="user-info">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-12  mb-3">
                <h6 class="text-primary">
                  معلومات المستخدم
                </h6>
              </div>
              <div class="col-md-4 col-6">
                <p class="m-0">معرف المستخدم</p>
              </div>
              <div class="col-md-8 col-6">
                <p class="text-primary m-0">
                  <router-link :to="'/users/individuals/details/' + encodeId(booking?.user?.id)">
                    {{ booking?.user?.id }}
                  </router-link>
                </p>
              </div>
              <div class="col-md-12">
                <hr>
              </div>
              <div class="col-md-4 col-6">
                <p class="m-0">اسم المستخدم</p>
              </div>
              <div class="col-md-8 col-6">
                <p class="m-0">{{ booking?.user?.first_name }} {{ booking?.user?.last_name }}</p>
              </div>
              <div class="col-md-12">
                <hr>
              </div>
              <div class="col-md-4 col-6">
                <p class="m-0">رقم جوال </p>
              </div>
              <div class="col-md-8 col-6">
                <p class="m-0">{{ booking?.user?.phone_country_code }}{{ booking?.user?.phone }}</p>
              </div>
              <div class="col-md-12">
                <hr>
              </div>
              <div class="col-md-4 col-6">
                <p class="m-0">البريد الالكتروني</p>
              </div>
              <div class="col-md-8 col-6">
                <p class="m-0">{{ booking?.user?.email }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card tab-content d-none2" id="transaction-info">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-12 mb-3">
                <h6 class="text-primary">
                  معاملة الحجز
                </h6>
              </div>
              <div class="col-md-12">
                <md-table v-model="payments" md-sort="name" md-sort-order="asc" md-card>
                  <md-table-row slot="md-table-row" slot-scope="{ item }">
                    <md-table-cell md-label="معرف الدفع" md-numeric>
                      {{ item.id }}
                    </md-table-cell>
                    <md-table-cell md-label="المبلغ" md-numeric>
                    </md-table-cell>
                    <md-table-cell md-label="الحالة" md-numeric>

                    </md-table-cell>
                    <md-table-cell md-label="بوابة الدفع" md-numeric>

                    </md-table-cell>
                    <md-table-cell md-label="الخصم" md-numeric>

                    </md-table-cell>
                    <md-table-cell md-label="الرسوم الاساسية" md-numeric>

                    </md-table-cell>
                    <md-table-cell md-label="الضريبة" md-numeric>

                    </md-table-cell>
                    <md-table-cell md-label="التاريخ" md-numeric>

                    </md-table-cell>
                    <md-table-cell md-label="نوع الاسترداد" md-numeric>

                    </md-table-cell>
                    <md-table-cell md-label="المبلغ المسترد" md-numeric>

                    </md-table-cell>
                    <md-table-cell md-label="إيصال الدفع" md-numeric>

                    </md-table-cell>
                  </md-table-row>
                </md-table>
                <div v-if="payments_count == 0" class="rt-noData">لا يوجد بيانات</div>
              </div>
            </div>
          </div>
        </div>
        <div class="card tab-content d-none2" id="activities-info">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-md-12 mb-3">
                <h6 class="text-primary">
                  سجل نشاط الحجز
                </h6>
              </div>
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row align-items-center">
                      <div class="col-md-2">
                        11/09/2023
                        11:00:03
                        GMT+03:00
                      </div>
                      <div class="col-md-1">
                        <div style="height: 75px;width: 1px;background-color: #ededed;"></div>
                      </div>
                      <div class="col-md-9">
                        <p class="m-0">
                         طلب الحجز بواسطة : {{ booking?.user?.first_name }} {{ booking?.user?.last_name }}
                        </p>
                        <p class="m-0">
                          الحالة :   <span v-bind:class="booking.status"> {{
                            $t(booking.status) }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import $ from 'jquery';
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import jsonDataAr from '@/assets/file/ar-airports.json';
import jsonDataEn from '@/assets/file/en-airports.json';
import airlinesData from '@/assets/file/airlines.json';
export default {
  page: {
    title: "booking details",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      title: "booking details",
      booking: [],
      payments: [{}, {}, {}, {}, {}, {}],
      payments_count: 0,
      flight_info: [],
      departure: null,
      arrival: null,
      departure_flight: [],
      arrival_flight: [],
    };
  },
  mounted() {
    this.jquery();
    $('.page-loader-div').show();
    var url = `${this.$appUrl}/api/admin/bookings/show/${this.$route.params.id}`;
    this.axios.get(url, { headers: { 'Authorization': `Bearer ${localStorage.getItem("access_token")}` } }).then((response) => {
      this.booking = response.data.booking;
      var jsonData = jsonDataAr;
      if (this.$i18n.locale === 'en') {
        jsonData = jsonDataEn;
      }
      this.airlines = airlinesData;
      this.departure = jsonData.filter(item => {
        return item.code.includes(this.booking.from);
      })[0].city;
      this.arrival = jsonData.filter(item => {
        return item.code.includes(this.booking.to);
      })[0].city;
      this.flight_info = response.data.booking.flight_info[0];
      this.departure_flight = this.flight_info.itineraries[0];
      this.arrival_flight = this.flight_info.itineraries[1];
      $('.content-div').removeClass('d-none2');
      $('.page-loader-div').hide();
    });
  },
  methods: {
    encodeId(id) {
      return btoa(id * 987654321);
    },
    convertDuration(duration) {
      const regex = /PT(\d+)H(\d+)M/;
      const regex2 = /PT(\d+)H/;
      const match = regex.exec(duration);
      const match2 = regex2.exec(duration);
      if (match) {
        const hours = match[1].padStart(2, '0');
        const minutes = match[2].padStart(2, '0');
        if (this.$i18n.locale === 'en') {
          return hours + 'h ' + minutes + 'm';
        }
        return hours + 'س ' + minutes + 'د';
      } else if (match2) {
        const hours = match2[1].padStart(2, '0');
        const minutes = '00';
        if (this.$i18n.locale === 'en') {
          return hours + 'h ' + minutes + 'm';
        }
        return hours + 'س ' + minutes + 'د';
      }
      return '';
    },
    get_airport_info(iataCode) {
      var jsonData = jsonDataAr;
      if (this.$i18n.locale === 'en') {
        jsonData = jsonDataEn;
      }
      return jsonData.filter(item => {
        return item.code.includes(iataCode);
      });
    },
    get_stop_duration(current, next) {
      const dateTime1 = new Date(current.departure.at);
      const dateTime2 = new Date(next.arrival.at);
      const differenceInMs = Math.abs(dateTime2 - dateTime1);
      const hours = Math.floor(differenceInMs / (1000 * 60 * 60));
      const minutes = Math.floor((differenceInMs % (1000 * 60 * 60)) / (1000 * 60));
      return {
        hours: hours,
        minutes: minutes
      };
    },
    jquery() {
      $(document).on('click', '.menu-info .tab-link', function () {
        var id = $(this).data("id");
        $(document).find('.menu-info .tab-link').removeClass('active');
        $(this).addClass('active');
        $(document).find('.tab-content').hide();
        $(id).show();
      });
    }
  }
};
</script>
<style lang="scss">
.md-table.md-theme-default .md-table-row td {
  text-align: center;
}

.md-table.md-theme-default .md-table-head {
  text-align: center;
}

.page-loader {
  position: absolute;
  margin: 0px auto;
  left: 0;
  right: 0;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 10;
  text-align: center;

  .md-progress-spinner {
    top: 50%;
  }
}

.page-loader-parent:has(.page-loader) {
  position: relative;
  min-height: 500px;
}

.table-responsive:has(.page-loader-parent) {
  min-height: 350px;
}

.md-icon.md-theme-default.md-icon-image svg {
  display: none;
}

.card {
  .md-field>.md-icon:after {
    background-color: rgb(0 0 0 / 0%);
  }
}

.menu-info {
  text-align: initial;

  a {
    color: #000 !important;
    width: 100%;
    display: block;
    border-top: 1px solid #ededed;
    padding: 15px 20px;
    font-size: 16px;

    &.active {
      background-color: #f7f7f7;
    }

    i {
      display: inline-block;
      min-width: 1.75rem;
      padding-bottom: 0.125em;
      line-height: 1.40625rem;
      vertical-align: middle;
      color: #00a99d;
      -webkit-transition: all 0.4s;
      transition: all 0.4s;
      font-size: 18px;
    }
  }
}

.inline-block {
  display: inline-block;
}

.vertical-middle {
  vertical-align: middle !important;
}

.bold {
  font-weight: 600 !important;
  font-style: normal;
}

.prime-color {
  color: #1a1f71;
}

.flip {
  transform: scaleX(-1);
}

.oneway,
.oneway-2x,
.oneway-left,
.roundtrip,
.roundtrip-2x {
  background-repeat: no-repeat;
  display: inline-block;
  width: 50px;
  height: 26px;
  background-position: center;
  vertical-align: middle;
}

.roundtrip {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAVCAMAAACJ68VtAAAASFBMVEUAAAC4uLnLU424uLm4uLm4uLnFd5zLU43LU43LU43LU424uLm4uLm4uLnLU43LU424uLnLU43LU424uLnLU43LU43LU424uLmCgW4gAAAAFnRSTlMAwMAw8GAQ0GCg8NKwgEAwIJBwUFAgC1wdigAAAF5JREFUKM+1jjkOwCAMBJ1w5oCQy/7/T1OCUxiBxHSrmWJhDBG96N+1EhwdwTmXOMSHe4c/ruxUAo51uORlKMhaqxYNUdZgfXGNaJtKdsvapGUPSpMBTntwV4IAQ/gAxM4FYv8HXysAAAAASUVORK5CYII=);
}

.mb0 {
  margin-bottom: 0px;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
}

.booking-details {
  p {
    margin: 0;
  }
}

.oneway {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAVBAMAAABMGyhsAAAAIVBMVEUAAAC4uLm4uLm4uLm4uLm4uLm4uLm4uLm4uLm4uLm4uLnBqJdvAAAACnRSTlMAwPAwYNRAELAgsHcJxgAAADhJREFUGNNjoC0QMkAT8FJGE2BZhFtJhSAYiC6aABUoXQUFaHqMlqDymbUc8CtgkHJAE0hjoB0AAAGXDOZBKlNPAAAAAElFTkSuQmCC);
}

.rtl .oneway,
.rtl .roundtrip {
  transform: scaleX(-1);
}

.layover {
  text-align: center;
  padding: 10px 0;
  border-left-color: transparent;
  border-right-color: transparent;
  background-color: rgba(230, 240, 255, .7);
  margin: 0 15px;
  color: #507bbc;
}

.rtl .fl-itinerary>div:first-child {
  border-left: 1px dashed #f0eeee;
  border-right: 0px;
}

.rtl .fl-itinerary>div:last-child {
  border-right: 1px dashed #f0eeee;
  border-left: 0px;
}

.mb15 {
  margin-bottom: 15px !important;
}

.p20 {
  padding: 20px;
}
</style>